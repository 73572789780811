import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Baseurl, Baseurl2 } from "../../config/BaseUrl";
import axios from "axios";
import { set_checkout_authentication_status } from "../../redux/athentication/Athentication";
import { Affix, Slider, Spin } from "antd";
import SingleProduct from "../../component/product/SingleProduct";
import NoProductFound from "../../component/NoProductFound";
// import { Footer, Header } from "antd/es/layout/layout";
// import Hadear from "../../component/layout/Hadear";
import { signout } from "../../redux/athentication/Athentication";
import { getCartTotal, set_all_amount_data } from "../../redux/cart/CartSlice";
import { FaArrowLeft } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { BsHandbag } from "react-icons/bs";
import { IoFilter } from "react-icons/io5";
import { BiSort } from "react-icons/bi";
import { PiSortAscendingLight } from "react-icons/pi";
import { GoSortAsc } from "react-icons/go";
import { AiOutlineSortDescending } from "react-icons/ai";
import { AiOutlineSortAscending } from "react-icons/ai";
import Offcanvas from "react-bootstrap/Offcanvas";
// import { BsShop } from "react-icons/bs";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import MainFilter from "../search/MainFilter";

const SubCategories = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const sidebarRef = useRef();
  const {
    CartItems,
    amountToGetfeeDelivery,
    amountToGetfeeDeliveryPercentage,
    TotalPrice,
    TotalAmount,
    HandllingFee,
  } = useSelector((state) => state.cart);
  const { isAuth } = useSelector((state) => state.Athentication);
  const { categoryShow } = useSelector((store) => store.category);
  const { categoryTagShow } = useSelector((store) => store.categorytag);
  const { subcategoryShow } = useSelector((store) => store.subCategories);
  const { mainHomeSliderTotal } = useSelector((store) => store.slider);


  const [search, setSearch] = useState("");
  const [subcatSearchResult, setSubcatSearchResult] = useState([]);
  const [categoryTagSearchResult, setCategoryTagSearchResult] = useState([]);
  const [categorySearchResult, setCategorySearchResult] = useState([]);
  const [ProductSearchResult, setProductSearchResult] = useState([]);

  const [showResults, setShowResults] = useState(false);

  const [current_category, setcurrent_category] = useState("");
  const [selected_category_tag, setselected_category_tag] = useState([]);
  const [category_tags, setcategory_tags] = useState([]);
  const [category_products, setcategory_products] = useState([]);
  const [tag_loading, settag_Loading] = useState(true);
  const [product_loading, setproduct_Loading] = useState(true);
  const [productThere, setproductThere] = useState(false);
  const [filtered_products, setfiltered_products] = useState([]);
  const [allproductLoading, setallproductLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const [minSlideValue, setMinSlideValue] = useState(0);
  const [maxSlideValue, setMaxSlideValue] = useState(1000);

  const [sortPrice, setSortPrice] = useState([]);
  const [sortAlphabeticalOrder, setSortAlphabeticalOrder] = useState([]);
  const [selectedValue, setSelectedValue] = useState([
    minSlideValue,
    maxSlideValue,
  ]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [filterShow, setFilterShow] = useState(false);

  const handleFilterClose = () => setFilterShow(false);
  const handleFilterShow = () => setFilterShow(true);

  const [gotLimitProduct, setGotLimitProduct] = useState(false);
  const [gotIncreasedLimitProduct, setGotIncreasedLimitProduct] =
    useState(false);

  const [filterSelectedCloseArray, setfilterSelectedCloseArray] = useState([]);

  const onChangeComplete = (value) => { };
  const selectCloserFilterPress = (value) => {
    if (filterSelectedCloseArray.includes(value)) {
      setfilterSelectedCloseArray(
        filterSelectedCloseArray.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setfilterSelectedCloseArray((filterSelectedCloseArray) => [
        ...filterSelectedCloseArray,
        value,
      ]);
    }
  };

  const onChange = async (value) => {
    await setSelectedValue(value);
    setTimeout(() => {
      setShowFilterMenu(false);
    }, 2000);
  };

  useEffect(() => {
    dispatch(getCartTotal());
  }, [CartItems]);
  

  const handleFilter = () => {
    setShowFilterMenu(!showFilterMenu);
    window.scrollTo(0, 0);
  };

  const searchchange = (e) => {
    const result = e.target.value;
    setSearch(result);

    if (result.length >= 2) {
      const filter_category = categoryShow
        .filter((category) =>
          category.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);
      const filter_sub_category = subcategoryShow
        .filter((sub_category) =>
          sub_category.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);
      const filter_category_tag = categoryTagShow
        .filter((category_tag) =>
          category_tag.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);

      setCategorySearchResult(filter_category);
      setSubcatSearchResult(filter_sub_category);
      // setCategoryTagSearchResult(filter_category_tag);
      setShowResults(true);
    }
  };

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  const searchsubmit = (e) => {
    e.preventDefault();
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
      setSearch("");
    } else {
      alert("Please type something...");
    }
  };

  const serchclick = () => {
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
    }
  };

  useEffect(() => {
    if (params.sub_category) {
      const get_sub_Category = async () => {
        const url = `${Baseurl}/api/v1/subcategory/slugurl/${params.sub_category}`;
        const sub_categoryRespo = await axios.get(url);
        if (sub_categoryRespo.data.success) {
          const sub_category_data = sub_categoryRespo.data.subcategory;
          setcurrent_category(sub_category_data);
          const tag_url = `${Baseurl}/api/v1/categorytag/category-tags-by-sub-category/${sub_category_data.name}`;
          const category_tag_Respo = await axios.get(tag_url);
          if (category_tag_Respo.data.success) {
            const category_tag_data = category_tag_Respo.data.ctegoryTags;
            setcategory_tags(category_tag_data);
            settag_Loading(false);
          }

          const product_url = `${Baseurl2}/api/v1/product/all-products-by-sub-category-limit/${sub_category_data.name}`;
          const product_Respo = await axios.get(product_url);
          if (product_Respo.data.success) {
            const product_data = product_Respo.data.products;
            if (product_data.length > 0) {
              setproductThere(true);
            }
            await setcategory_products(product_data);
            await setfiltered_products(product_data);
            setTimeout(() => {
              setproduct_Loading(false);
            }, 1000);
            setTimeout(() => {
              setGotLimitProduct(true);
            }, 3000);
          }
        }
      };

      get_sub_Category();
    }
  }, [params.sub_category]);
  useEffect(() => {
    if (gotLimitProduct) {
      const get_Products = async () => {
        const product_url = `${Baseurl2}/api/v1/product/all-products-by-sub-category-limitIncrease/${current_category.name}`;

        const product_Respo = await axios.get(product_url);
        if (product_Respo.data.success) {
          const product_data = product_Respo.data.products;
          setcategory_products(product_data);
          setfiltered_products(product_data);
          setallproductLoading(false);
        }
      };

      get_Products();
    }
  }, [gotLimitProduct]);


  useEffect(() => {

    if (filtered_products.length > 0) {
      const find_cat = categoryShow.find((data) => data.name === filtered_products[0].category)
      setcurrent_category(find_cat)
    }
  }, [filtered_products])

  const handletagPress = (e, value) => {
    if (e.target.checked) {
      setselected_category_tag([...selected_category_tag, value]);
    } else {
      setselected_category_tag(
        selected_category_tag.filter((tag) => tag !== value)
      );
    }
    // setFilterShow(false);
  };
  useEffect(() => {
    if (selected_category_tag.length === 0) {
      const products = category_products;
      setfiltered_products(products);
      const prices = products.map(
        (product) => product.packSize[0].PackSizeOptions[0].Price
      );
      const lowestPrice = Math.min(...prices);
      setMinSlideValue(lowestPrice);
      const highestPrice = Math.max(...prices);
      setMaxSlideValue(highestPrice);
    } else {
      const products = category_products.filter((item) =>
        selected_category_tag.includes(item.CategoryTag)
      );
      setfiltered_products(products);
      const prices = products.map(
        (product) => product.packSize[0].PackSizeOptions[0].Price
      );
      const lowestPrice = Math.min(...prices);
      setMinSlideValue(lowestPrice);
      const highestPrice = Math.max(...prices);
      setMaxSlideValue(highestPrice);
    }
  }, [selected_category_tag, category_products]);

  useEffect(() => {
    const getProducts = category_products;
    const slideFilterProduct = getProducts.filter(
      (product) =>
        product.packSize[0].PackSizeOptions[0].Price >= selectedValue[0] &&
        product.packSize[0].PackSizeOptions[0].Price <= selectedValue[1]
    );
    setfiltered_products(slideFilterProduct);
  }, [selectedValue]);

  useEffect(() => {
    const prices = filtered_products.map(
      (product) => product.packSize[0].PackSizeOptions[0].Price
    );
    const lowestPrice = Math.min(...prices);
    setMinSlideValue(lowestPrice);
    const highestPrice = Math.max(...prices);
    setMaxSlideValue(highestPrice);
  }, []);

  const selectFilterChange = (value) => {
    setSortAlphabeticalOrder(value);
  };
  useEffect(() => {
    let sortedProducts = [...filtered_products];
    if (sortAlphabeticalOrder === "title-descending") {
      sortedProducts = sortedProducts.sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
      setfiltered_products(sortedProducts);
    } else if (sortAlphabeticalOrder === "title-ascending") {
      sortedProducts = sortedProducts.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setfiltered_products(sortedProducts);
    }
  }, [sortAlphabeticalOrder]);

  const selectPriceChange = (value) => {
    setSortPrice(value);
  };
  useEffect(() => {
    let sortedProducts = [...filtered_products];
    if (sortPrice === "High to Low") {
      sortedProducts = sortedProducts.sort((a, b) => {
        return (
          b.packSize[0].PackSizeOptions[0].Price -
          a.packSize[0].PackSizeOptions[0].Price
        );
      });
      setfiltered_products(sortedProducts);
    } else if (sortPrice === "Low to High") {
      sortedProducts = sortedProducts.sort((a, b) => {
        return (
          a.packSize[0].PackSizeOptions[0].Price -
          b.packSize[0].PackSizeOptions[0].Price
        );
      });
      setfiltered_products(sortedProducts);
    }
  }, [sortPrice]);
  const catSearchClick = (category) => {
    if (search !== "") {
      navigate(`/search/${category.superCategory}/${category.slugUrl}`);
      setShowResults(false);
      setSearch("");
    }
  };
  const signoutClick = () => {
    dispatch(signout());
    navigate("/");
  };

  const getfilt_Prod = (value) => {
    setfiltered_products([...value]);
  };

  return (
    <div style={{ position: "relative" }}>
      <div>
        <div>


          <Affix offsetTop={0}>

          </Affix>
        </div>
        <div className="desktopHeader" style={{ backgroundColor: "#2F415D" }}>
          <div className="leftHeaderDesktop">
            <div>
              <div className="logo col-5 col-sm-3 col-md-2 col-lg-3 align-self-center is-fixed desktopVersion">
                <Link className="logoImg" to="/">
                  <img
                    src="/assets/images/logonew14.png"
                    alt="EwShopping"
                    title="EwShopping"
                    className="imglogo"
                  />
                </Link>
              </div>
            </div>
            {
              mainHomeSliderTotal && mainHomeSliderTotal.map((indCat) =>
                <Link to={`/category/${indCat.slugUrl}`} >
                  <h6 className="categorynametext">{indCat.name.toUpperCase()}</h6>
                </Link>
              )}
          </div>
          <div className="rightHeaderDesktop">
            <div
              className="inputDivisionDesktop"
              onSubmit={(e) => searchsubmit(e)}
            >
              <span>
                <CiSearch
                  style={{ fontSize: "1.5rem" }}
                  type="submit"
                  onClick={serchclick}
                />
              </span>
              <input
                placeholder="search for products...."
                type="text"
                value={search}
                onChange={searchchange}
              />
            </div>
            {showResults && categorySearchResult.length > 0 && (
              <>
                <div className="serchsugist">
                  {categorySearchResult.length > 0 && (
                    <ul className="">
                      {categorySearchResult &&
                        categorySearchResult.map((category, index) => (
                          <li className="item" key={index}>
                            <div
                              className="mini-list-item d-flex align-items-center w-100 clearfix"
                              onClick={() => catSearchClick(category)}
                            >
                              <div className="mini-image text-center">
                                <Link className="item-link">
                                  <img
                                    className="blur-up lazyload"
                                    data-src={category.desktopImage}
                                    src={category.desktopImage}
                                    alt={category.name}
                                    title="product"
                                    width={120}
                                    height={170}
                                  />
                                </Link>
                              </div>
                              <div className="ms-3 details text-left">
                                <div className="product-name">
                                  <Link className="item-title">
                                    {category.name}
                                  </Link>
                                </div>

                                <Link>
                                  <div className="product-review d-flex align-items-center justify-content-start">
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        color: "#2f415d",
                                      }}
                                    >
                                      in {category.superCategory}
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  )}
                  {subcatSearchResult.length > 0 && (
                    <ul>
                      {subcatSearchResult &&
                        subcatSearchResult.map((sub_category, index) => (
                          <li className="item" key={index}>
                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                              <div className="mini-image text-center">
                                <Link
                                  className="item-link"
                                  to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                  onClick={() => setShowResults(false)}
                                >
                                  <img
                                    className="blur-up lazyload"
                                    data-src={sub_category.desktopImage}
                                    src={sub_category.desktopImage}
                                    alt={sub_category.name}
                                    title="product"
                                    width={120}
                                    height={170}
                                  />
                                </Link>
                              </div>
                              <div className="ms-3 details text-left">
                                <div className="product-name">
                                  <Link
                                    className="item-title"
                                    to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                    onClick={() => setShowResults(false)}
                                  >
                                    {sub_category.name}
                                  </Link>
                                </div>

                                <div className="product-review d-flex align-items-center justify-content-start">
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      color: "#2f415d",
                                    }}
                                  >
                                    in {sub_category.superCategory}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  )}
                  {categoryTagSearchResult.length > 0 && (
                    <ul>
                      {categoryTagSearchResult &&
                        categoryTagSearchResult.map((category_tag, index) => (
                          <li className="item" key={index}>
                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                              <div className="mini-image text-center">
                                <Link
                                  className="item-link"
                                  to={`/tag-search/${category_tag.slugUrl}`}
                                  onClick={() => setShowResults(false)}
                                >
                                  <img
                                    className="blur-up lazyload"
                                    data-src={category_tag.desktopImage}
                                    src={category_tag.desktopImage}
                                    alt={category_tag.name}
                                    title="product"
                                    width={120}
                                    height={170}
                                  />
                                </Link>
                              </div>
                              <div className="ms-3 details text-left">
                                <div className="product-name">
                                  <Link
                                    className="item-title"
                                    to={`/tag-search/${category_tag.slugUrl}`}
                                    onClick={() => setShowResults(false)}
                                  >
                                    {category_tag.name}
                                  </Link>
                                </div>

                                <div className="product-review d-flex align-items-center justify-content-start">
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      color: "#2f415d",
                                    }}
                                  >
                                    in {category_tag.SubCategory}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </>
            )}

            <div className="accountpiecesDesk account-parent iconset">
              {/* <LuUser className="accountpiecesDeskicon  anm-user-al" />
              <span className="accountpiecesDesktext anm anm-user-al">Profile</span> */}
              <div className="account-link" title="Account">
                <i className="hdr-icon icon anm anm-user-al" />
              </div>
              <div id="accountBox">
                <div className="customer-links">
                  <ul className="m-0">
                    {isAuth === true ? (
                      <>
                        <li>
                          <Link to="/my-account">
                            <i className="icon anm anm-user-cil" />
                            My Account
                          </Link>
                        </li>
                        <li onClick={() => signoutClick()}>
                          <i className="icon anm anm-sign-out-al" />
                          Sign out
                        </li>
                      </>
                    ) : (
                      <>
                        <li
                          onClick={() => {
                            dispatch(set_checkout_authentication_status(0));
                            navigate("/login");
                          }}
                        >
                          <Link to="/login">
                            <i className="icon anm anm-user-cil" />
                            Sign In
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div>
              {/* <MdOutlineShoppingBag className="accountpiecesDeskicon" />
              <span className="accountpiecesDesktext">Bag</span> */}
              <div
                className="accountpiecesDesk header-cart iconset"
                title="Cart"
              >
                <Link
                  className="header-cart btn-minicart clr-none"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#minicart-drawer"
                >
                  <i className="hdr-icon icon anm anm-cart-l" />
                  <span className="cart-count">{CartItems.length}</span>
                </Link>

                <div
                  id="minicart-drawer"
                  className="minicart-right-drawer offcanvas offcanvas-end"
                  tabIndex={-1}
                >
                  {CartItems.length === 0 ? (
                    <>
                      <div
                        id="cartEmpty"
                        className="cartEmpty d-flex-justify-center flex-column text-center p-3 text-muted d-block"
                      >
                        <div className="minicart-header d-flex-center justify-content-between w-100">
                          <h4 className="fs-6">
                            Your cart ({CartItems.length} Items)
                          </h4>
                          <button
                            className="close-cart border-0"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          >
                            <i
                              className="icon anm anm-times-r"
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              title="Close"
                            />
                          </button>
                        </div>
                        <div className="cartEmpty-content mt-4">
                          <i className="icon anm anm-cart-l fs-1 text-muted" />
                          <p className="my-3">No Products in the Cart</p>
                          <span
                            className="btn btn-primary cart-btn"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            onClick={() => navigate("/")}
                          >
                            Continue shopping
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div id="cart-drawer" className="block block-cart">
                        <div className="minicart-header">
                          <button
                            className="close-cart border-0"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          >
                            <i
                              className="icon anm anm-times-r"
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              title="Close"
                            />
                          </button>
                          <h4 className="fs-6">
                            Your cart ({CartItems.length} Items)
                          </h4>
                        </div>
                        <div className="minicart-content">
                          <ul className="m-0 clearfix">
                            {CartItems &&
                              CartItems.map((item, index) => (
                                <li
                                  className="item d-flex justify-content-center align-items-center"
                                  key={index}
                                >
                                  <span className="product-image rounded-3">
                                    <img
                                      className="blur-up lazyload"
                                      data-src={item.thumbnail}
                                      src={item.thumbnail}
                                      alt="product"
                                      title="Product"
                                      width={120}
                                      height={170}
                                    />
                                  </span>
                                  <div className="product-details">
                                    <span
                                      className="product-title limited-paragraph"
                                      onClick={() => {
                                        if ("slugurl" in item) {
                                          navigate(
                                            `/product-info/${item.slugurl}`
                                          );
                                        }
                                      }}
                                      data-bs-dismiss="offcanvas"
                                      aria-label="Close"
                                    >
                                      {item.ProductName}
                                    </span>
                                    <div className="variant-cart my-2 limited-paragraph">
                                      {item.packSizeOptionName} /{" "}
                                      {item.packSizeName}
                                    </div>
                                    <div className="priceRow">
                                      <div className="product-price">
                                        <span className="price">
                                          {" "}
                                          ₹{item.Price}
                                        </span>{" "}
                                        x{" "}
                                        <span className="price">
                                          {" "}
                                          {item.cart_Quentity}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                        <div className="minicart-bottom">
                          <div className="shipinfo">
                            {amountToGetfeeDelivery !== 0 && (
                              <div className="progress mb-2">
                                <div
                                  className="progress-bar progress-bar-striped progress-bar-animated"
                                  role="progressbar"
                                  aria-valuenow={70}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                  style={{
                                    width: `${amountToGetfeeDeliveryPercentage}%`,
                                  }}
                                >
                                  {amountToGetfeeDeliveryPercentage}%
                                </div>
                              </div>
                            )}

                            {amountToGetfeeDeliveryPercentage === 0 ? (
                              <>
                                <div className="freeShipMsg">
                                  <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                  Only{" "}
                                  <span
                                    className="money"
                                    data-currency-usd=" ₹199.00"
                                    data-currency="USD"
                                  >
                                    {" "}
                                    ₹{amountToGetfeeDelivery}
                                  </span>{" "}
                                  away from
                                  <b>Free Shipping</b>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="freeShipMsg d-block">
                                  <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                  Congrats! You are eligible for
                                  <b>Free Shipping</b>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="subtotal clearfix my-3">
                            <div className="totalInfo clearfix mb-1 d-block">
                              <span>Total:</span>
                              <span className="item product-price">
                                {" "}
                                ₹{TotalPrice}
                              </span>
                            </div>
                            <div className="totalInfo clearfix mb-1 d-block">
                              <span>Handlling Fee:</span>
                              <span className="item product-price">
                                {" "}
                                ₹{HandllingFee}
                              </span>
                            </div>
                            <div className="totalInfo clearfix">
                              <span>Netpayable:</span>
                              <span className="item product-price">
                                {" "}
                                ₹{TotalAmount}
                              </span>
                            </div>
                          </div>

                          <div className="minicart-action d-flex mt-3">
                            <span
                              onClick={() => navigate("/check-out")}
                              className="proceed-to-checkout btn btn-primary w-50 me-1"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              Check Out
                            </span>
                            <span
                              onClick={() => navigate("/cart")}
                              className="cart-btn btn btn-secondary w-50 ms-1"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              View Cart
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className="accountpiecesDesk"
              onClick={() =>
                window.open(`https://seller.ewshopping.com`, "_blank")
              }
            >
              <span className="accountpiecesDesktext">Sell on EWShopping</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mainshirtscontainer">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="shirtscontainer">
            <FaArrowLeft size={22} onClick={() => navigate("/")} />
            <div style={{ marginLeft: "15px" }}>
              <div
                className="shirtstext"
                style={{ fontWeight: "600", fontSize: "13px" }}
              >
                {params.subCategories}
              </div>
              <div
                className="itemstext"
                style={{ fontWeight: "600", fontSize: "14px" }}
              >
                {filtered_products.length} items
              </div>
            </div>
          </div>
          <div className="iconmaincontainer">
            <span>
              {" "}
              <CiSearch
                className="cisearch"
                onClick={() => navigate("/searchproductpage")}
              />
            </span>

            <span onClick={() => navigate("/cart")}>
              <BsHandbag
                className="bshandbag"
                style={{ position: "relative" }}
              />
              <span
                style={{
                  position: "absolute",
                  backgroundColor: "black",
                  color: "#fff",
                  top: "5px",
                  right: "20px",
                  padding: "0px 5px",
                  borderRadius: "70%",
                }}
              >
                {CartItems.length}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div id="page-content" className="shop-body">
        <div className="container categorcontainer">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3 sidebar sidebar-bg filterbar">
              <div className="closeFilter d-block d-lg-none">
                <i className="icon anm anm-times-r" />
              </div>
              <div className="sidebar-tags sidebar-sticky clearfix">
                {/* <div className="sidebar-widget filterBox filter-widget">
                  <div className="widget-title">
                    <h2>Filter By</h2>
                  </div>
                  <div className="widget-content filterby filterDD">
                    <ul className="items tags-list d-flex-wrap">
                      <li className="item">
                        <Link to="#;" className="rounded-5">
                          <span className="filter-value">Women</span>
                          <i className="icon anm anm-times-r" />
                        </Link>
                      </li>
                      <li className="item">
                        <Link to="#;" className="rounded-5">
                          <span className="filter-value">Blue</span>
                          <i className="icon anm anm-times-r" />
                        </Link>
                      </li>
                      <li className="item">
                        <Link to="#;" className="rounded-5">
                          <span className="filter-value">XL</span>
                          <i className="icon anm anm-times-r" />
                        </Link>
                      </li>
                    </ul>
                    <Link to="#;" className="btn btn-sm brd-link">
                      Clear All
                    </Link>
                  </div>
                </div> */}

                <div className="sidebar-widget clearfix categories filterBox filter-widget">
                  <div
                    className={
                      filterSelectedCloseArray.includes("1")
                        ? "widget-title active"
                        : "widget-title"
                    }
                    onClick={() => selectCloserFilterPress("1")}
                  >
                    <h2>Categories</h2>
                  </div>
                  <div
                    className="widget-content filterDD"
                    style={
                      filterSelectedCloseArray.includes("1")
                        ? {
                          display: "none",
                        }
                        : {}
                    }
                  >
                    <ul className="sidebar-categories scrollspy morelist clearfix">
                      {tag_loading === false &&
                        category_tags &&
                        category_tags.map((tag, index) => (
                          <li key={index}>
                            <input
                              type="checkbox"
                              id={`tag-${index}`}
                              name="avone"
                              value={tag.name}
                              checked={selected_category_tag.includes(tag.name)}
                              onChange={(e) => handletagPress(e, tag.name)}
                            />
                            <label htmlFor={`tag-${index}`}>
                              <span />
                              {tag.name}
                            </label>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="sidebar-widget filterBox filter-widget">
                  <div
                    className={
                      filterSelectedCloseArray.includes("2")
                        ? "widget-title active"
                        : "widget-title"
                    }
                    onClick={() => selectCloserFilterPress("2")}
                  >
                    <h2>Price</h2>
                  </div>
                  <div
                    className="widget-content price-filter filterDD"
                    style={
                      filterSelectedCloseArray.includes("2")
                        ? {
                          display: "none",
                        }
                        : {}
                    }
                  >
                    <div id="slider-range" className="mt-2" />
                    <div className="row">
                      <div className="col-12">
                        <Slider
                          range
                          step={10}
                          defaultValue={[20, 50]}
                          onChange={onChange}
                          onChangeComplete={onChangeComplete}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <MainFilter
                  current_category={
                    current_category
                  }
                  filterSelectedCloseArray={filterSelectedCloseArray}
                  category_products={category_products}
                  filtered_products={filtered_products}
                  selected_category_tag={selected_category_tag}
                  selectCloserFilterPress={selectCloserFilterPress}
                  getfilt_Prod={getfilt_Prod}
                />
                {/*End Price Filter*/}
                {/*Color Swatches*/}
                {/* <div className="sidebar-widget filterBox filter-widget">
                  <div className="widget-title">
                    <h2>Color</h2>
                  </div>
                  <div className="widget-content filter-color filterDD">
                    <ul className="swacth-list swatches d-flex-center clearfix pt-0">
                      <li className="swatch large radius available active">
                        <img
                          src="assets/images/products/swatches/blue-red.jpg"
                          alt="image"
                          width={70}
                          height={70}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Blue"
                        />
                      </li>
                      <li className="swatch large radius available">
                        <img
                          src="assets/images/products/swatches/blue-red.jpg"
                          alt="image"
                          width={70}
                          height={70}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Black"
                        />
                      </li>
                      <li className="swatch large radius available">
                        <img
                          src="assets/images/products/swatches/blue-red.jpg"
                          alt="image"
                          width={70}
                          height={70}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Pink"
                        />
                      </li>
                      <li className="swatch large radius available">
                        <img
                          src="assets/images/products/swatches/blue-red.jpg"
                          alt="image"
                          width={70}
                          height={70}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Red"
                        />
                      </li>
                      <li className="swatch large radius available black">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Black"
                        />
                      </li>
                      <li className="swatch large radius available red">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Red"
                        />
                      </li>
                      <li className="swatch large radius available blue">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Blue"
                        />
                      </li>
                      <li className="swatch large radius available pink">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Pink"
                        />
                      </li>
                      <li className="swatch large radius available gray">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Gray"
                        />
                      </li>
                      <li className="swatch large radius available green">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Green"
                        />
                      </li>
                      <li className="swatch large radius available orange">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Orange"
                        />
                      </li>
                      <li className="swatch large radius soldout yellow">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Yellow"
                        />
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/*End Color Swatches*/}
                {/*Size Swatches*/}
                {/* <div className="sidebar-widget filterBox filter-widget">
                  <div className="widget-title">
                    <h2>Size</h2>
                  </div>
                  <div className="widget-content filter-size filterDD">
                    <ul className="swacth-list size-swatches d-flex-center clearfix">
                      <li className="swatch large radius soldout">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="XS"
                        >
                          XS
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="S"
                        >
                          S
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="M"
                        >
                          M
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="L"
                        >
                          L
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="X"
                        >
                          X
                        </span>
                      </li>
                      <li className="swatch large radius available active">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="XL"
                        >
                          XL
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="XLL"
                        >
                          XLL
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="XXL"
                        >
                          XXL
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={25}
                        >
                          25
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={35}
                        >
                          35
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={40}
                        >
                          40
                        </span>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/*End Size Swatches*/}
                {/*Product Brands*/}
                {/* <div className="sidebar-widget filterBox filter-widget brand-filter">
                  <div className="widget-title">
                    <h2>Brands</h2>
                  </div>
                  <div className="widget-content filterDD">
                    <ul className="clearfix">
                      <li>
                        <input
                          type="checkbox"
                          defaultValue="avone"
                          id="avone"
                        />
                        <label htmlFor="avone">
                          <span />
                          Avone
                        </label>
                      </li>
                      <li>
                        <input type="checkbox" defaultValue="diva" id="diva" />
                        <label htmlFor="diva">
                          <span />
                          Diva
                        </label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          defaultValue="optimal"
                          id="optimal"
                        />
                        <label htmlFor="optimal">
                          <span />
                          Optimal
                        </label>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/*End Product Brands*/}
                {/*Product Tags*/}
                {/* <div className="sidebar-widget filterBox filter-widget product-tag">
                  <div className="widget-title">
                    <h2>Product Tags</h2>
                  </div>
                  <div className="widget-content filterDD">
                    <ul className="tags-list product-tags d-flex-wrap clearfix">
                      <li className="item active">
                        <Link className="rounded-5" to="#">
                          Women
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          Shoes
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          Beauty
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          Accessories
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          ₹100 - ₹400
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          Above ₹800
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          Black
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          Blue
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          Red
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          M
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          XS
                        </Link>
                      </li>
                    </ul>
                    <span className="btn btn-sm brd-link btnview">
                      View all
                    </span>
                  </div>
                </div> */}
                {/*End Product Tags*/}
                {/*Banner*/}
                {/* <div className="sidebar-widget static-banner p-0">
                  <Link to="shop-left-sidebar.html">
                    <img
                      className="rounded-0 blur-up lazyload"
                      data-src="assets/images/banners/shop-banner.jpg"
                      src="assets/images/banners/shop-banner.jpg"
                      alt="image"
                      width={274}
                      height={367}
                    />
                  </Link>
                </div> */}
                {/*End Banner*/}
              </div>
            </div>
            {/*End Sidebar*/}
            {/*Products*/}
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 main-col">
              {/*Toolbar*/}
              <div className="toolbar toolbar-wrapper shop-toolbar">
                <div className="row align-items-center">
                  {/* <div className="col-4 col-sm-2 col-md-4 col-lg-4 text-left filters-toolbar-item d-flex order-1 order-sm-0">
                    <button
                      type="button"
                      className="btn btn-filter icon anm anm-sliders-hr d-inline-flex d-lg-none me-2"
                    >
                      <span className="d-none">Filter</span>
                    </button>
                  </div> */}
                  {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-right filters-toolbar-item d-flex justify-content-end order-2 order-sm-2">
                    <div className="filters-item d-flex align-items-center ms-2 ms-lg-3">
                      <label
                        htmlFor="SortBy"
                        className="mb-0 me-2 text-nowrap d-none"
                      >
                        Sort by:
                      </label>
                      <select
                        name="SortBy"
                        id="SortBy"
                        className="filters-toolbar-sort"
                        onChange={(e) => selectFilterChange(e)}
                      >
                        <option value="featured " selected="selected" hidden>
                          Select filter
                        </option>
                        <option value="title-ascending">
                          Alphabetically, A-Z
                        </option>
                        <option value="title-descending">
                          Alphabetically, Z-A
                        </option>
                      </select>
                    </div>
                  </div> */}
                </div>
              </div>

              {product_loading === true && filtered_products ? (
                <>
                  <div className="example">
                    <Spin />
                  </div>
                </>
              ) : (
                <>
                  {productThere ? (
                    <>
                      <div className="grid-products grid-view-items">
                        <div className="row col-row product-options row-cols-lg-4 row-cols-md-3 row-cols-sm-3 row-cols-2">
                          {filtered_products &&
                            filtered_products
                              .slice(0, 100)
                              .map((product, index) => (
                                <SingleProduct key={index} product={product} />
                              ))}
                          {product_loading === false &&
                            allproductLoading === true && (
                              <div className="example_new">
                                <Spin />
                              </div>
                            )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="no_product_container">
                        <NoProductFound />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="footermaincontainer">
        <div>
          <div className="footermaincontainer1">
            <div style={{ display: "flex" }} onClick={handleShow}>
              <BiSort className="bisort" />
              <div className="sorttext">SORT</div>
            </div>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="bottom"
              style={{
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            >
              <Offcanvas.Header closeButton style={{ padding: "20px 20px" }}>
                <Offcanvas.Title
                  style={{ fontWeight: "600", fontSize: "16px", margin: "0px" }}
                >
                  Sort By:
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div style={{ padding: "5px" }}>
                  <div
                    style={{ display: "flex" }}
                    onClick={(e) => {
                      selectPriceChange("High to Low");
                      setShow(false);
                    }}
                  >
                    <GoSortAsc size={20} />
                    <h6
                      style={{
                        color: "#666",
                        fontWeight: "600",
                        paddingLeft: "5px",
                      }}
                    >
                      Price: High to Low
                    </h6>
                  </div>
                  <div
                    style={{ display: "flex" }}
                    onClick={(e) => {
                      selectPriceChange("Low to High");
                      setShow(false);
                    }}
                  >
                    <PiSortAscendingLight size={20} />
                    <h6
                      style={{
                        color: "#666",
                        fontWeight: "600",
                        paddingLeft: "5px",
                      }}
                    >
                      Price: Low to High
                    </h6>
                  </div>
                  <div
                    style={{ display: "flex" }}
                    onClick={(e) => {
                      selectFilterChange("title-ascending");
                      setShow(false);
                    }}
                  >
                    <AiOutlineSortAscending size={20} />
                    <h6
                      style={{
                        color: "#666",
                        fontWeight: "600",
                        paddingLeft: "5px",
                      }}
                    >
                      Alphabetically, A-Z
                    </h6>
                  </div>
                  <div
                    style={{ display: "flex" }}
                    onClick={(e) => {
                      selectFilterChange("title-descending");
                      setShow(false);
                    }}
                  >
                    <AiOutlineSortDescending size={20} />
                    <h6
                      style={{
                        color: "#666",
                        fontWeight: "600",
                        paddingLeft: "5px",
                      }}
                    >
                      Alphabetically, Z-A
                    </h6>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
            <div
              style={{ display: "flex" }}
              onClick={(e) => handleFilterShow()}
            >
              <IoFilter className="iofilter" />
              <div className="sorttext">FILTER</div>
            </div>
            <Offcanvas show={filterShow} onHide={handleFilterClose}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title
                  style={{ fontSize: "18px", fontWeight: "600", margin: "0px" }}
                >
                  CATEGORIES
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <ul className="sidebar-categories scrollspy morelist clearfix">
                  {tag_loading === false &&
                    category_tags &&
                    category_tags.map((tag, index) => (
                      <li key={index} style={{ padding: "3px 0px" }}>
                        <input
                          type="checkbox"
                          id={`tag-${index}`}
                          name="avone"
                          value={tag.name}
                          checked={selected_category_tag.includes(tag.name)}
                          onChange={(e) => handletagPress(e, tag.name)}
                        />
                        <label htmlFor={`tag-${index}`} className="ps-1">
                          <span />
                          {tag.name.length > 20
                            ? `${tag.name.slice(0, 20)}...`
                            : tag.name}
                        </label>
                      </li>
                    ))}
                </ul>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                    borderBottom: ".5px solid #ccc",
                    padding: "5px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      marginTop: "20px",
                    }}
                  >
                    PRICE:
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Slider
                      range
                      step={10}
                      min={Number(minSlideValue)}
                      max={Number(maxSlideValue)}
                      onChange={onChange}
                      value={selectedValue}
                    />
                  </div>
                </div>
                {/* ----here mobile version----- */}
                <MainFilter
                  current_category={
                    current_category
                  }
                  filterSelectedCloseArray={filterSelectedCloseArray}
                  category_products={category_products}
                  filtered_products={filtered_products}
                  selected_category_tag={selected_category_tag}
                  selectCloserFilterPress={selectCloserFilterPress}
                  getfilt_Prod={getfilt_Prod}
                />
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "60px" }}></div>
    </div>
  );
};

export default SubCategories;
