import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const PopularNewComponent = ({ subCatByCat }) => {
  const { subcategorytotal } = useSelector((store) => store.subCategories);
  return (
    <>
      {subCatByCat.length > 0 && (
        <section className="section collection-slider pl-4 pb-4">
          <div className="container">
            {/* <div className="section-header">
              <p className="mb-2 mt-0">Shop by category</p>
              <h2>Popular Collections</h2>
            </div> */}
            <div className="heading" style={{ marginTop: "-10px" }}>
              <h2 className="TitleHeading">
                Popular Collections
              </h2>
            </div>
            <div className="collection-slider-5items gp15 arwOut5 hov-arrow">
              {/* <ShopByCategorySlider /> */}
              <Swiper
                grabCursor={true}
                navigation={false}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                modules={[
                  Navigation,
                  // Pagination,
                  Mousewheel,
                  Keyboard,
                  Autoplay,
                ]}
                loop={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  "@0.75": {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  "@1.00": {
                    slidesPerView: 6,
                    spaceBetween: 25,
                  },
                  "@1.50": {
                    slidesPerView: 6,
                    spaceBetween: 25,
                  },
                }}
                className="mySwiper"
              >
                {subCatByCat &&
                  subCatByCat.map((showData, index) => (
                    <SwiperSlide key={index}>
                      <div className="category-item zoomscal-hov">
                        <Link
                          to={`/shop/${showData.slugUrl}`}
                          className="category-link clr-none"
                        >
                          <div className="zoom-scal zoom-scal-nopb rounded-3">
                            <img
                              className="blur-up lazyload"
                              src={showData.desktopImage}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  "assets/images/collection/sub-collection3.jpg";
                              }}
                              alt= {showData.name}
                              title= {showData.name}
                              width={365}
                              height={365}
                            />
                          </div>
                          <div
                            className="details mt-3 text-center detailsSwipper"
                            style={{ minHeight: "65px",maxHeight: "65px" }}
                          >
                            <h4 className="category-title mb-0">
                              {showData.name}
                            </h4>
                            {/* <p className="counts">
                              {showData.product_Length} Products
                            </p> */}
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PopularNewComponent;
